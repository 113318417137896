import { gql } from "@apollo/client";

export const UPDATE_ASSET_INTEGRATION = gql`
    mutation UpdateAssetIntegration(
        $id: ID!
        $update: UpdateAssetIntegrationInput!
    ) {
        updateOneLocationIntegration(input: { id: $id, update: $update }) {
            id
            type
            assetId
            allocationNote
        }
    }
`;

export const GET_ASSET_INTEGRATION_ALLOCATIONS = gql`
    query GetAssetIntegrationAllocations($assetIntegrationId: ID!) {
        locationIntegration(id: $assetIntegrationId) {
            id
            allocations {
                ...AssetIntegrationAllocationItem
            }
        }
    }
    fragment AssetIntegrationAllocationItem on AssetIntegrationAllocation {
        id
        percentage
        assetId
        propertyId
    }
`;

export const UPDATE_ASSET_INTEGRATION_ALLOCATION = gql`
    mutation UpdateAssetIntegrationAllocation(
        $id: String!
        $type: AllocationObjectType!
        $allocations: [AssetIntegrationAllocationInput!]!
    ) {
        updateAssetIntegrationAllocations(
            input: { id: $id, allocationType: $type, allocations: $allocations }
        ) {
            id
            allocations {
                id
                percentage
                assetId
                propertyId
            }
        }
    }
`;

export const UPDATE_NOTE_FRAGMENT = gql`
    fragment Note on AssetAutomaticDataSource {
        allocationNote
    }
`;

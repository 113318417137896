import { MenuItem, Select, styled } from "@mui/material";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import { DataSourceStatusFilter } from "containers/AssetPage/AssetDataOverview/types/dataSourceTypes";
import { convertToRem } from "utils/maths";

import { getStatusColor } from "../../automatic-sources-table.helpers";

export const S = {
    Select: styled(Select)(() => ({
        background: "#fff",
        width: convertToRem(150),

        "&.Mui-focused fieldset": {
            background: "transparent",
            color: "currentColor",
        },

        ".MuiOutlinedInput-notchedOutline": { border: 0 },
    })),

    MenuItem: styled(MenuItem)<{ i: number; status?: DataSourceStatusFilter }>(
        ({ theme, i, status }) => {
            const statusColor =
                status && getStatusColor(status as unknown as DataSourceStatus);

            return {
                "&.MuiMenuItem-root": {
                    padding: 0,
                    borderLeftWidth: status ? 5 : 0,
                    borderLeftStyle: "solid",
                    borderLeftColor:
                        statusColor && theme.palette[statusColor].main,
                    borderTopWidth: 1,
                    borderTopStyle: "solid",
                    borderTopColor:
                        i !== 0 ? theme.palette.divider : "transparent",
                },
            };
        }
    ),
};

import { gql } from "@apollo/client";

export const GET_INTEGRATIONS_ALLOCATED_ON_ASSET = gql`
    query GetIntegrationsAllocatedOnAsset($assetId: ID!) {
        locationIntegrations(
            filter: { allocations: { assetId: { eq: $assetId } } }
        ) {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

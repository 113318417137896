import {
    Box,
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    OutlinedInput,
    SelectChangeEvent,
} from "@mui/material";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize, entries } from "lodash";
import { MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    AllocationObjectType,
    ConsumptionType,
    IntegrationType,
} from "graphql-types/graphql";
import {
    getAllocationObjectTypeTranslation,
    getConsumptionTypeTranslation,
    getIntegrationTypeTranslation,
} from "utils/translations";

import {
    filterAutomaticSources,
    getFilterRenderValues,
    getStatusFilterRenderValues,
} from "./automatic-source-toolbar.helpers";
import { S } from "./Filters/AutomaticSourceFilter.style";
import { AutomaticSource } from "../../asset-sources.helpers";
import { DataSourceStatusFilter } from "../../types/dataSourceTypes";

export const DEFAULT_STATUS_FILTER = [DataSourceStatusFilter.ACTIVE];

type Props = {
    sources: AutomaticSource[];
    locationDisplayName?: string | null;
    setSources: (sources: AutomaticSource[]) => void;
    apiRef: MutableRefObject<GridApiCommunity>;
};

const AutomaticSourceToolbar = (props: Props) => {
    const { locationDisplayName, sources, setSources, apiRef } = props;
    const { t } = useTranslation(["report"]);
    const { useConsumptionAllocation } = useFlags();
    const fileName = `${locationDisplayName} - ${t(
        "automaticSources.fileName",
        "automatic_sources",
        { ns: "translation" }
    )}`;

    const [statusFilter, setStatusFilter] = useState<DataSourceStatusFilter[]>(
        DEFAULT_STATUS_FILTER
    );

    const [sourceOptions, setSourceOptions] = useState<IntegrationType[]>([]);
    const [sourceFilter, setSourceFilter] = useState<IntegrationType[]>([]);

    const [consumptionTypeOptions, setConsumptionTypeOptions] = useState<
        ConsumptionType[]
    >([]);
    const [consumptionTypeFilter, setConsumptionTypeFilter] = useState<
        ConsumptionType[]
    >([]);

    const [propertyAllocationFilter, setPropertyAllocationFilter] = useState<
        AllocationObjectType[]
    >(Object.values(AllocationObjectType));

    useEffect(() => {
        const availableSources = Object.values(IntegrationType).filter((type) =>
            sources.find((source) => source.type === type)
        );
        const availableConsumptionTypes = Object.values(ConsumptionType).filter(
            (type) => sources.find((source) => source.consumptionType === type)
        );

        setConsumptionTypeOptions(availableConsumptionTypes);
        setConsumptionTypeFilter(availableConsumptionTypes);

        setSourceOptions(availableSources);
        setSourceFilter(availableSources);

        setSources(
            filterAutomaticSources(
                sources,
                DEFAULT_STATUS_FILTER,
                availableSources,
                availableConsumptionTypes,
                useConsumptionAllocation
                    ? Object.values(AllocationObjectType)
                    : []
            )
        );
    }, [sources, setSources, useConsumptionAllocation]);

    const onChangeStatus = (e: SelectChangeEvent<unknown>) => {
        const value =
            // On autofill we get a stringified value.
            typeof e.target.value === "string"
                ? (e.target.value.split(",") as DataSourceStatusFilter[])
                : (e.target.value as DataSourceStatusFilter[]);

        setStatusFilter(value);
        setSources(
            filterAutomaticSources(
                sources,
                value,
                sourceFilter,
                consumptionTypeFilter,
                useConsumptionAllocation
                    ? Object.values(AllocationObjectType)
                    : []
            )
        );
    };

    const onChangeSource = (e: SelectChangeEvent<unknown>) => {
        const value =
            // On autofill we get a stringified value.
            typeof e.target.value === "string"
                ? (e.target.value.split(",") as IntegrationType[])
                : (e.target.value as IntegrationType[]);

        setSourceFilter(value);
        setSources(
            filterAutomaticSources(
                sources,
                statusFilter,
                value,
                consumptionTypeFilter,
                useConsumptionAllocation
                    ? Object.values(AllocationObjectType)
                    : []
            )
        );
    };

    const onChangeConsumptionType = (e: SelectChangeEvent<unknown>) => {
        const value =
            // On autofill we get a stringified value.
            typeof e.target.value === "string"
                ? (e.target.value.split(",") as ConsumptionType[])
                : (e.target.value as ConsumptionType[]);

        setConsumptionTypeFilter(value);
        setSources(
            filterAutomaticSources(
                sources,
                statusFilter,
                sourceFilter,
                value,
                useConsumptionAllocation
                    ? Object.values(AllocationObjectType)
                    : []
            )
        );
    };

    const onChangePropertyAllocation = (e: SelectChangeEvent<unknown>) => {
        const value =
            // On autofill we get a stringified value.
            typeof e.target.value === "string"
                ? (e.target.value.split(",") as AllocationObjectType[])
                : (e.target.value as AllocationObjectType[]);

        setPropertyAllocationFilter(value);
        setSources(
            filterAutomaticSources(
                sources,
                statusFilter,
                sourceFilter,
                consumptionTypeFilter,
                useConsumptionAllocation ? value : []
            )
        );
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", pb: 3 }}>
            <Box sx={{ display: "flex", gap: 8, alignItems: "center" }}>
                <FormControl>
                    <InputLabel id="status-select-label" size="small">
                        {t("common.labels.status", "Status", {
                            ns: "translation",
                        })}
                    </InputLabel>
                    <S.Select
                        multiple
                        size="small"
                        labelId="status-select-label"
                        id="status-select"
                        label={t("common.labels.status", {
                            ns: "translation",
                        })}
                        input={<OutlinedInput label="Status" />}
                        value={statusFilter}
                        defaultValue={DEFAULT_STATUS_FILTER}
                        renderValue={(selectedValues) =>
                            getFilterRenderValues(
                                selectedValues as string[],
                                Object.values(DataSourceStatusFilter).length,
                                getStatusFilterRenderValues
                            )
                        }
                        onChange={onChangeStatus}
                    >
                        {entries(DataSourceStatusFilter).map(
                            ([key, value], i) => (
                                <S.MenuItem
                                    key={key}
                                    value={value}
                                    i={i}
                                    status={value}
                                >
                                    <Checkbox
                                        checked={statusFilter.includes(value)}
                                        size="small"
                                    />
                                    <ListItemText primary={capitalize(value)} />
                                </S.MenuItem>
                            )
                        )}
                    </S.Select>
                </FormControl>

                <FormControl>
                    <InputLabel id="source-select-label" size="small">
                        {t("common.labels.source", { ns: "translation" })}
                    </InputLabel>
                    <S.Select
                        multiple
                        size="small"
                        labelId="source-select-label"
                        label={t("common.labels.source", { ns: "translation" })}
                        id="source-select"
                        value={sourceFilter}
                        defaultValue={sourceOptions}
                        onChange={onChangeSource}
                        renderValue={(selectedValues) =>
                            getFilterRenderValues(
                                selectedValues as string[],
                                sourceOptions.length,
                                (selectedValues) => {
                                    return selectedValues
                                        .map((value) =>
                                            getIntegrationTypeTranslation(
                                                value as IntegrationType
                                            )
                                        )
                                        .join(", ");
                                }
                            )
                        }
                    >
                        {sourceOptions.map((value, i) => (
                            <S.MenuItem
                                key={`${value}-${i}`}
                                value={value}
                                i={i}
                            >
                                <Checkbox
                                    checked={sourceFilter.includes(value)}
                                    size="small"
                                />
                                <ListItemText
                                    primary={getIntegrationTypeTranslation(
                                        value
                                    )}
                                />
                            </S.MenuItem>
                        ))}
                    </S.Select>
                </FormControl>

                <FormControl>
                    <InputLabel id="consumptionType-select-label" size="small">
                        {t(
                            "common.labels.consumptionType",
                            "Consumption Type",
                            { ns: "translation" }
                        )}
                    </InputLabel>
                    <S.Select
                        multiple
                        size="small"
                        labelId="consumptionType-select-label"
                        id="consumptionType-select"
                        label={t("common.labels.consumptionType", {
                            ns: "translation",
                        })}
                        value={consumptionTypeFilter}
                        defaultValue={consumptionTypeOptions}
                        onChange={onChangeConsumptionType}
                        renderValue={(selectedValues) =>
                            getFilterRenderValues(
                                selectedValues as string[],
                                consumptionTypeOptions.length,
                                (selectedValues) => {
                                    return selectedValues
                                        .map((value) =>
                                            getConsumptionTypeTranslation(
                                                value as ConsumptionType
                                            )
                                        )
                                        .join(", ");
                                }
                            )
                        }
                    >
                        {consumptionTypeOptions.map((value, i) => (
                            <S.MenuItem
                                key={`${value}-${i}`}
                                value={value}
                                i={i}
                            >
                                <Checkbox
                                    checked={consumptionTypeFilter.includes(
                                        value
                                    )}
                                    size="small"
                                />
                                <ListItemText
                                    primary={getConsumptionTypeTranslation(
                                        value as ConsumptionType
                                    )}
                                />
                            </S.MenuItem>
                        ))}
                    </S.Select>
                </FormControl>

                {useConsumptionAllocation && (
                    <FormControl>
                        <InputLabel
                            id="status-property-allocation-label"
                            size="small"
                        >
                            {t(
                                "common.labels.propertyAllocation",
                                "Property Allocation",
                                {
                                    ns: "translation",
                                }
                            )}
                        </InputLabel>
                        <S.Select
                            multiple
                            size="small"
                            labelId="status-property-allocation-label"
                            id="property-allocation-select"
                            label={t("common.labels.propertyAllocation", {
                                ns: "translation",
                            })}
                            input={
                                <OutlinedInput
                                    label={t(
                                        "common.labels.propertyAllocation",
                                        {
                                            ns: "translation",
                                        }
                                    )}
                                />
                            }
                            value={propertyAllocationFilter}
                            defaultValue={propertyAllocationFilter}
                            renderValue={(selectedValues) =>
                                getFilterRenderValues(
                                    selectedValues as string[],
                                    Object.values(AllocationObjectType).length,
                                    (selectedValues) => {
                                        return selectedValues
                                            .map((value) =>
                                                getAllocationObjectTypeTranslation(
                                                    value as AllocationObjectType
                                                )
                                            )
                                            .join(", ");
                                    }
                                )
                            }
                            onChange={onChangePropertyAllocation}
                        >
                            {entries(AllocationObjectType).map(
                                ([key, value], i) => (
                                    <S.MenuItem key={key} value={value} i={i}>
                                        <Checkbox
                                            checked={propertyAllocationFilter.includes(
                                                value
                                            )}
                                            size="small"
                                        />
                                        <ListItemText
                                            primary={getAllocationObjectTypeTranslation(
                                                value
                                            )}
                                        />
                                    </S.MenuItem>
                                )
                            )}
                        </S.Select>
                    </FormControl>
                )}
            </Box>
            <Box sx={{ display: "flex", gap: 5, alignItems: "center" }}>
                <span>
                    {sources.length}{" "}
                    {t("pageheader.data", "Data Sources", {
                        ns: "translation",
                    })}
                </span>
                <Button
                    variant="contained"
                    onClick={() =>
                        apiRef?.current.exportDataAsCsv({
                            fileName,
                        })
                    }
                >
                    {t("common.download", "Download", {
                        ns: "translation",
                    })}
                </Button>
            </Box>
        </Box>
    );
};

export default AutomaticSourceToolbar;

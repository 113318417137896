import { TFunction } from "i18next";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import { AssetAutomaticDataSourceItemFragment } from "graphql-types/graphql";

export type AutomaticSource = AssetAutomaticDataSourceItemFragment;

const isAutomaticSourceEPC = (source: AutomaticSource) => {
    return source.type.includes("EPC");
};

export const getAutomaticSourceType = (
    source: AutomaticSource,
    t: TFunction
) => {
    if (isAutomaticSourceEPC(source)) {
        return source.type.split("_").join(" ");
    }

    return t("dataQuality.source.automaticMeter", "Automatic Meter", {
        ns: "translation",
    });
};

export const getAutomaticSourceStatus = (status?: boolean | null) => {
    switch (status) {
        case true:
            return DataSourceStatus.ACTIVE;
        default: {
            return DataSourceStatus.EXPIRED;
        }
    }
};

import { Checkbox } from "@mui/material";

import { NumberInput } from "glue";

import { S } from "./drawerFields.style";
import { formatToMaxTwoDecimals } from "./PropertyAllocationField/property-allocation.helpers";

export type EditItem = {
    key: string;
    label: string;
    value: number;
    isChecked: boolean;
};

type Props = {
    items: EditItem[];
    disabled?: boolean;
    onCheckItem: (index: string) => void;
    onEditItem: (index: string, value: number) => void;
};

export const EditableFieldList = (props: Props) => {
    const { items, disabled, onEditItem, onCheckItem } = props;

    return (
        <S.BorderBox>
            {items.map((item) => (
                <S.FieldBox key={item.key}>
                    <S.InputLabel htmlFor={item.label} bold={item.isChecked}>
                        {!disabled && (
                            <Checkbox
                                size="small"
                                checked={item.isChecked}
                                onChange={() => onCheckItem(item.key)}
                            />
                        )}
                        {item.label}
                    </S.InputLabel>

                    {item.isChecked && (
                        <NumberInput
                            size="small"
                            endAdornment="%"
                            disabled={disabled}
                            value={item.value}
                            inputProps={{ min: 0, max: 100, step: 0.01 }}
                            onChange={(e) => onEditItem(item.key, Number(formatToMaxTwoDecimals(e.target.value)))}
                        />
                    )}
                </S.FieldBox>
            ))}
        </S.BorderBox>
    );
};

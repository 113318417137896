import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import { GridEventListener, useGridApiRef } from "@mui/x-data-grid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    DYNAMIC_GRID_TABLE_HEIGHT,
    Table,
    TablePagination,
} from "components/Table";
import {
    GetAssetAutomaticSourcesQuery,
    GetAssetAutomaticSourcesQueryVariables,
} from "graphql-types/graphql";

import {
    formatAssetIntegrationsToAutomaticSources,
    formatAutomaticDataRows,
    getAllocationColumns,
    getColumns,
} from "./automatic-sources-table.helpers";
import {
    ASSET_AUTOMATIC_SOURCES,
} from "./automaticSourcesTable.query";
import { AutomaticSourceRowData } from "./automaticSourcesTable.types";
import AutomaticSourceToolbar from "./components/AutomaticSourceToolbar";
import { DataSourceDrawer } from "./components/DataSourceDrawer";
import { AutomaticSource } from "../asset-sources.helpers";

type Props = {
    locationId: string;
};

export const AutomaticSourcesTable = (props: Props) => {
    const { locationId } = props;
    const { t } = useTranslation(["report"]);

    const { useConsumptionAllocation, useAssetIntegrationAsDataSource } =
        useFlags();

    const { data, loading } = useQuery<
        GetAssetAutomaticSourcesQuery,
        GetAssetAutomaticSourcesQueryVariables
    >(ASSET_AUTOMATIC_SOURCES, {
        variables: {
            locationId,
            useAssetIntegration: useAssetIntegrationAsDataSource,
        },
    });

    const [rows, setRows] = useState<AutomaticSource[]>([]);
    const [selectedSource, setSelectedSource] = useState<
        AutomaticSourceRowData | undefined
    >(undefined);

    const [sourceDrawerOpen, setSourceDrawerOpen] = useState(false);

    const columns = useMemo(() => {
        const defaultColumns = getColumns(t, useAssetIntegrationAsDataSource);

        if (!useConsumptionAllocation) return defaultColumns;

        return [...defaultColumns, ...getAllocationColumns(t)];
    }, [t, useConsumptionAllocation, useAssetIntegrationAsDataSource]);

    const apiRef = useGridApiRef();

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        setSourceDrawerOpen(true);

        const source = { assetName: data?.location.displayName, ...params.row };

        setSelectedSource(source);
    };

    const sources = useMemo(
        () => data?.location.automaticDataSources ?? [],
        [data]
    );
    const sourcesRows = useMemo(() => {
        if (useAssetIntegrationAsDataSource) {
            const assetIntegrations = data?.location.integrations ?? [];
            const sources = assetIntegrations.map((integration) => ({
                ...integration,
                address: integration.address ?? data?.location.longAddress,
            }));

            return formatAssetIntegrationsToAutomaticSources(sources);
        }

        return formatAutomaticDataRows(rows);
    }, [data?.location, rows, useAssetIntegrationAsDataSource]);

    return (
        <Box>
            <AutomaticSourceToolbar
                apiRef={apiRef}
                sources={sources}
                setSources={setRows}
                locationDisplayName={data?.location.displayName}
            />
            <DataSourceDrawer
                data={selectedSource}
                isOpen={sourceDrawerOpen}
                onClose={() => setSourceDrawerOpen(false)}
            />
            <Table
                sx={{
                    padding: 0,
                    "& .MuiDataGrid-columnHeader[data-field='source']": {
                        paddingLeft: 6,
                    },
                    "& .MuiDataGrid-cell[data-field='source']": {
                        paddingLeft: 0,
                    },
                    "& .MuiDataGrid-columnHeader": {
                        cursor: "pointer",
                    },
                    "& .MuiDataGrid-root": {
                        padding: 0,
                    },
                }}
                columns={columns}
                rows={sourcesRows}
                onRowClick={useConsumptionAllocation ? onRowClick : undefined}
                setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
                minHeight="400px"
                apiRef={apiRef}
                slotProps={{
                    pagination: {
                        ActionsComponent: TablePagination,
                    },
                }}
                isLoading={loading}
            />
        </Box>
    );
};

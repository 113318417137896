import { MenuItem, Select } from "@mui/material";

import { AllocationObjectType } from "graphql-types/graphql";
import { getPropertyObjectTypeTranslation } from "utils/translations";

type Props = {
    value: AllocationObjectType;
    onChange: (objectType: AllocationObjectType) => void;
};

export const SelectAllocationType = (props: Props) => {
    const { value, onChange } = props;

    const options = Object.values(AllocationObjectType).map((type) => {
        const label = getPropertyObjectTypeTranslation(type);

        return { label, value: type };
    });

    return (
        <Select
            fullWidth
            size="small"
            variant="outlined"
            value={value}
            onChange={(e) => onChange(e.target.value as AllocationObjectType)}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

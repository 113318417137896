import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

import { PropertyAllocationItem } from "./property-allocation.helpers";
import { S } from "../drawerFields.style";

type Props = {
    propertyList: PropertyAllocationItem[];
};

export const PropertyAllocationViewField = (props: Props) => {
    const { propertyList } = props;

    const list = propertyList.map((item) => {
        const { id, depth, name, allocation } = item;

        const color = allocation ? "auto" : "grey.400";

        const icon =
            depth >= 0 ? (
                <SubdirectoryArrowRightIcon
                    fontSize="small"
                    htmlColor={color}
                />
            ) : null;

        const paddingLeft = depth < 0 ? "auto" : `${(depth + 1) * 20}px`;

        return (
            <S.ListItem key={id} style={{ paddingLeft }}>
                <S.ListItemText
                    fontWeight={allocation ? "bold" : "normal"}
                    color={color}
                >
                    {icon} {name}
                </S.ListItemText>
                {allocation && <S.NumberText>{allocation}%</S.NumberText>}
            </S.ListItem>
        );
    });

    return <S.BorderBox>{list}</S.BorderBox>;
};

import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
    GetAllAssetDetailsQuery,
    AddAssetPropertyMutation,
    AddAssetPropertyMutationVariables,
    DeleteAssetPropertyMutation,
    DeleteAssetPropertyMutationVariables,
    GetIntegrationsAllocatedOnAssetQuery,
} from "graphql-types/graphql";

import AssetMasterData from "./AssetMasterData";
import { AssetPropertyList } from "./components";
import { AddPropertyModal, ChangeLogModal } from "./modals";
import { ADD_ASSET_PROPERTY, DELETE_ASSET_PROPERTY } from "./queries";
import { GET_INTEGRATIONS_ALLOCATED_ON_ASSET } from "./queries/integrations-allocated-on-asset.query";
import { ModalProperty } from "./types";

type Props = {
    editsCount: number;
    asset: GetAllAssetDetailsQuery["location"] | undefined;
};

export const AssetDetailsContent = (props: PropsWithChildren<Props>) => {
    const { asset, editsCount, children } = props;

    const { t } = useTranslation();
    const { useConsumptionAllocation } = useFlags();

    const [openChangeLog, setOpenChangeLog] = useState(false);
    const [openAddPropertyModal, setOpenAddPropertyModal] = useState(false);

    const [integrationsOnAssetCount, setIntegrationsOnAssetCount] = useState<
        number | null
    >(null);

    const { data: integrationsOnAsset, loading } =
        useQuery<GetIntegrationsAllocatedOnAssetQuery>(
            GET_INTEGRATIONS_ALLOCATED_ON_ASSET,
            {
                variables: {
                    assetId: asset?.id || "",
                },
                skip: !useConsumptionAllocation,
            }
        );

    useEffect(() => {
        if (loading || !integrationsOnAsset) {
            return;
        }

        const count = integrationsOnAsset.locationIntegrations.edges.length;
        setIntegrationsOnAssetCount(count);
    }, [integrationsOnAsset, loading]);

    const [addAssetProperty] = useMutation<
        AddAssetPropertyMutation,
        AddAssetPropertyMutationVariables
    >(ADD_ASSET_PROPERTY, {
        update(cache) {
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    const [deleteAssetProperty] = useMutation<
        DeleteAssetPropertyMutation,
        DeleteAssetPropertyMutationVariables
    >(DELETE_ASSET_PROPERTY, {
        update(cache) {
            cache.evict({ id: "ROOT_QUERY" });
        },
    });

    async function onSaveProperty(property: ModalProperty, parentId?: string) {
        if (!asset || !property.objectType || !property.ownershipType) {
            return;
        }

        await addAssetProperty({
            variables: {
                input: {
                    assetId: asset.id,
                    propertyName: property.name,
                    propertyObjectType: property.objectType,
                    propertyOwnershipType: property.ownershipType,
                    address: JSON.stringify(property.address),
                    parentPropertyId: parentId ?? "",
                },
            },
        });
    }

    function onPropertyDelete(propertyId: string) {
        deleteAssetProperty({ variables: { id: propertyId } });
    }

    if (!asset) {
        return (
            <Typography variant="h4" color="grey.400">
                {t(
                    "portfolioDashboard.sidePanel.buildingDetails.notFound",
                    "No building found with this National Building ID"
                )}
            </Typography>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: "grid",
                    gap: 4,
                    gridTemplateColumns: "3fr 7fr",
                }}
            >
                <AssetMasterData
                    asset={asset}
                    editsCount={editsCount}
                    onChangeLogOpenClick={() => setOpenChangeLog(true)}
                />

                {!useConsumptionAllocation && (
                    <Box>
                        <Typography variant="h3" mb={4}>
                            {t("assetDetailsComponent.buildings", "Buildings")}
                        </Typography>
                        {children}
                    </Box>
                )}

                {useConsumptionAllocation && (
                    <Box>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            mb={4}
                        >
                            <Typography variant="h3" fontWeight={700}>
                                {t(
                                    "assetDetailsComponent.propertyList.title",
                                    "Properties"
                                )}
                            </Typography>
                            <Box justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                        setOpenAddPropertyModal(true)
                                    }
                                >
                                    {t(
                                        "assetDetailsComponent.addProperty",
                                        "add property"
                                    )}
                                </Button>
                            </Box>
                        </Stack>
                        <AssetPropertyList
                            assetId={asset.id}
                            onItemDelete={onPropertyDelete}
                            onItemAdd={onSaveProperty}
                        />
                        {integrationsOnAssetCount && (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,
                                }}
                            >
                                <Typography variant="h4" color="grey.400">
                                    {t("common.thereAre", "There are ")}
                                </Typography>
                                <Link
                                    href={`/location-overview/${asset.id}/data-sources`}
                                >
                                    <Typography variant="h4" color="grey.400">
                                        {" "}
                                        {integrationsOnAssetCount}{" "}
                                        {integrationsOnAssetCount > 1
                                            ? t(
                                                  "common.labels.dataSources",
                                                  "data sources"
                                              )
                                            : t(
                                                  "common.labels.dataSource",
                                                  "data source"
                                              )}
                                    </Typography>
                                </Link>
                                <Typography variant="h4" color="grey.400">
                                    {t(
                                        "assetDetailsComponent.integrationsOnAsset.notAllocatedIntegrationsMessage",
                                        "on the asset that have not been allocated to a property."
                                    )}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <ChangeLogModal
                isOpen={openChangeLog}
                assetId={asset.id}
                assetName={asset.displayName || "-"}
                editsCount={editsCount}
                handleClose={() => setOpenChangeLog(false)}
            />
            <AddPropertyModal
                isOpen={openAddPropertyModal}
                onConfirm={(property) => onSaveProperty(property)}
                onClose={() => setOpenAddPropertyModal(false)}
            />
        </>
    );
};

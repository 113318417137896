import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetPropertyTree, PropertyObjectType, PropertyOwnershipType } from "graphql-types/graphql";
import { getPropertyObjectTypeTranslation, getPropertyOwnershipTranslation } from "utils/translations";

import { S } from "./add-property-modal.styles";
import { getPropertyTypeOptions } from "../../components";
import { ModalProperty } from "../../types";

type Props = {
    isOpen: boolean;
    assetProperty?: AssetPropertyTree | null;
    onConfirm: (
        property: ModalProperty,
        assetProperty?: AssetPropertyTree | null
    ) => void;
    onClose: () => void;
};

export const AddPropertyModal = (props: Props) => {
    const { isOpen, onClose, onConfirm, assetProperty } = props;

    const { t } = useTranslation();

    const [property, setProperty] = useState<ModalProperty>({
        name: "",
        objectType: "",
        ownershipType: "",
        address: {
            zip: "",
            city: "",
            street: "",
        },
    });

    function onCloseModal() {
        onClose();
        setProperty({
            name: "",
            objectType: "",
            ownershipType: "",
            address: {
                zip: "",
                city: "",
                street: "",
            },
        });
    }

    function onSaveProperty() {
        onConfirm(property as ModalProperty, assetProperty);
        onCloseModal();
    }

    function onAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
        setProperty({
            ...property,
            address: {
                ...property.address,
                [event.target.name]: event.target.value,
            },
        });
    }

    function onNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setProperty({
            ...property,
            name: event.target.value,
        });
    }

    function onObjectTypeValueChange(event: SelectChangeEvent<PropertyObjectType>) {
        setProperty({
            ...property,
            objectType: event.target.value as PropertyObjectType,
        });
    }

    function onOwnershipValueChange(event: SelectChangeEvent<string>) {
        setProperty({
            ...property,
            ownershipType: event.target.value as PropertyOwnershipType,
        });
    }

    const propertyTypeList = getPropertyTypeOptions(assetProperty?.objectType);

    const objectTypesOptions = propertyTypeList.map((item) => (
        <MenuItem value={item} key={item} sx={{ whiteSpace: "normal" }}>
            {getPropertyObjectTypeTranslation(item)}
        </MenuItem>
    ));

    const ownershipOptions = Object.values(PropertyOwnershipType).map((item) => (
        <MenuItem value={item} key={item} sx={{ whiteSpace: "normal" }}>
            {getPropertyOwnershipTranslation(item)}
        </MenuItem>
    ));

    const isSaveDisabled =
        property.objectType === "" ||
        property.name === "" ||
        property.ownershipType === "" ||
        property.address.zip === "" ||
        property.address.city === "" ||
        property.address.street === "";

    return (
        <S.Modal open={isOpen} onClose={onClose}>
            <S.ModalBody>
                <S.Header>
                    <S.HeaderTitle>
                        {t("assetDetailsComponent.addProperty", "add property")}
                    </S.HeaderTitle>
                    <S.ClosedIconBox>
                        <S.ClosedIcon onClick={onClose} />
                    </S.ClosedIconBox>
                </S.Header>
                <S.Body>
                    <S.Row>
                        <S.Title>
                            {t(
                                "assetDetailsComponent.propertyType",
                                "property type"
                            )}
                        </S.Title>
                        <Select
                            size="small"
                            fullWidth
                            value={property.objectType}
                            onChange={onObjectTypeValueChange}
                        >
                            {objectTypesOptions}
                        </Select>
                    </S.Row>
                    <S.Row>
                        <S.Title>
                            {t("assetDetailsComponent.name", "name")}
                        </S.Title>
                        <TextField
                            fullWidth
                            size="small"
                            value={property.name}
                            onChange={onNameChange}
                        />
                    </S.Row>

                    <S.Row>
                        <S.Title>
                            {t(
                                "assetDetailsComponent.ownershipType",
                                "ownerhsip type"
                            )}
                        </S.Title>
                        <Select
                            size="small"
                            fullWidth
                            value={property.ownershipType}
                            onChange={onOwnershipValueChange}
                        >
                            {ownershipOptions}
                        </Select>
                    </S.Row>

                    <S.Row>
                        <S.Title>{t("common.zipCode", "zip code")}</S.Title>
                        <TextField
                            name="zip"
                            fullWidth
                            size="small"
                            value={property.address.zip}
                            onChange={onAddressChange}
                        />
                    </S.Row>
                    <S.Row>
                        <S.Title>{t("common.city", "city")}</S.Title>
                        <TextField
                            name="city"
                            fullWidth
                            size="small"
                            value={property.address.city}
                            onChange={onAddressChange}
                        />
                    </S.Row>
                    <S.Row>
                        <S.Title>{t("common.street", "street")}</S.Title>
                        <TextField
                            name="street"
                            fullWidth
                            size="small"
                            value={property.address.street}
                            onChange={onAddressChange}
                        />
                    </S.Row>
                </S.Body>
                <S.Footer>
                    <S.Button variant="outlined" onClick={onCloseModal}>
                        {t("common.actions.cancel", "cancel")}
                    </S.Button>
                    <S.Button
                        variant="contained"
                        onClick={onSaveProperty}
                        disabled={isSaveDisabled}
                    >
                        {t("common.actions.save", "save")}
                    </S.Button>
                </S.Footer>
            </S.ModalBody>
        </S.Modal>
    );
};

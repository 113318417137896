import { useTranslation } from "react-i18next";

import { LegacyBenchmarkType } from "graphql-types/graphql";

export const useBenchmarkTranslations = () => {
    const { t } = useTranslation();

    const translations: Record<string, string> = {
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-100`]: t(
            "benchmark.bbr.100",
            "Farmhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-120`]: t(
            "benchmark.bbr.120",
            "Detached single family house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-121`]: t(
            "benchmark.bbr.121",
            "Single family house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-122`]: t(
            "benchmark.bbr.122",
            "Detached single family house in dense low-rise buildings"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-130`]: t(
            "benchmark.bbr.130",
            "(DEPRECATED) Row-, chain- or double house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-131`]: t(
            "benchmark.bbr.131",
            "Row-, chain- and clusterhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-132`]: t(
            "benchmark.bbr.132",
            "Double house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-140`]: t(
            "benchmark.bbr.140",
            "Apartment building and multifamily housing"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-150`]: t(
            "benchmark.bbr.150",
            "Dormitory"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-160`]: t(
            "benchmark.bbr.160",
            "Residential institution"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-185`]: t(
            "benchmark.bbr.185",
            "Residential annex"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-190`]: t(
            "benchmark.bbr.190",
            "Other residential"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-210`]: t(
            "benchmark.bbr.210",
            "(DEPRECATED) commercial production buildings related to agriculture and mining"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-211`]: t(
            "benchmark.bbr.211",
            "Pigsty"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-212`]: t(
            "benchmark.bbr.212",
            "Cowshed"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-213`]: t(
            "benchmark.bbr.213",
            "Poultry barn"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-214`]: t(
            "benchmark.bbr.214",
            "Mink farm"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-215`]: t(
            "benchmark.bbr.215",
            "Greenhouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-216`]: t(
            "benchmark.bbr.216",
            "Barn for feed and crop"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-217`]: t(
            "benchmark.bbr.217",
            "Garage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-218`]: t(
            "benchmark.bbr.218",
            "Hay shed"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-219`]: t(
            "benchmark.bbr.219",
            "Other building related to farming"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-220`]: t(
            "benchmark.bbr.220",
            "(DEPRECATED) Factory, workshop etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-221`]: t(
            "benchmark.bbr.221",
            "Industrial building with integrated production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-222`]: t(
            "benchmark.bbr.222",
            "Industrial building without integrated production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-223`]: t(
            "benchmark.bbr.223",
            "Workshop"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-229`]: t(
            "benchmark.bbr.229",
            "Other production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-230`]: t(
            "benchmark.bbr.230",
            "(DEPRECATED) Power-, Gas-, heating plant, waterworks, incinerator etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-231`]: t(
            "benchmark.bbr.231",
            "Energy production facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-232`]: t(
            "benchmark.bbr.232",
            "Supply- and energy distribution facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-233`]: t(
            "benchmark.bbr.233",
            "Water facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-234`]: t(
            "benchmark.bbr.234",
            "Waste- and sewage facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-239`]: t(
            "benchmark.bbr.239",
            "Other energy facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-290`]: t(
            "benchmark.bbr.290",
            "(DEPRECATED) Other farming and industrial facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-310`]: t(
            "benchmark.bbr.310",
            "(DEPRECATED) Transport and garage facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-311`]: t(
            "benchmark.bbr.311",
            "Rail and bus facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-312`]: t(
            "benchmark.bbr.312",
            "Aviation facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-313`]: t(
            "benchmark.bbr.313",
            "Parking and transport facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-314`]: t(
            "benchmark.bbr.314",
            "Residential garage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-315`]: t(
            "benchmark.bbr.315",
            "Seaport"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-319`]: t(
            "benchmark.bbr.319",
            "Other transport facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-320`]: t(
            "benchmark.bbr.320",
            "(DEPRECATED) Office, trade, warehouse or governmental administration building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-321`]: t(
            "benchmark.bbr.321",
            "Office building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-322`]: t(
            "benchmark.bbr.322",
            "Retail building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-323`]: t(
            "benchmark.bbr.323",
            "Warehouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-324`]: t(
            "benchmark.bbr.324",
            "Shopping center"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-325`]: t(
            "benchmark.bbr.325",
            "Gas station"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-329`]: t(
            "benchmark.bbr.329",
            "Other building for office, retail, and warehouse"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-330`]: t(
            "benchmark.bbr.330",
            "(DEPRECATED) Hotel, restaurant and service company"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-331`]: t(
            "benchmark.bbr.331",
            "Hotel, inn or conference center with overnight stay"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-332`]: t(
            "benchmark.bbr.332",
            "Bed & breakfast"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-333`]: t(
            "benchmark.bbr.333",
            "Restaurant, cafe and conference center without overnight stay"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-334`]: t(
            "benchmark.bbr.334",
            "Private service company"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-339`]: t(
            "benchmark.bbr.339",
            "Other building for service industry"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-390`]: t(
            "benchmark.bbr.390",
            "(DEPRECATED) Other building for transport, trade etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-410`]: t(
            "benchmark.bbr.410",
            "(DEPRECATED) Cinema, theater, library, museum, church etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-411`]: t(
            "benchmark.bbr.411",
            "Cinema, theater, concert hall"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-412`]: t(
            "benchmark.bbr.412",
            "Museum"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-413`]: t(
            "benchmark.bbr.413",
            "Library"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-414`]: t(
            "benchmark.bbr.414",
            "Church or other building for exercise of faith"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-415`]: t(
            "benchmark.bbr.415",
            "Assembly hall"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-416`]: t(
            "benchmark.bbr.416",
            "Amusement park"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-419`]: t(
            "benchmark.bbr.419",
            "Other cultural building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-420`]: t(
            "benchmark.bbr.420",
            "(DEPRECATED) Educational building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-421`]: t(
            "benchmark.bbr.421",
            "School"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-422`]: t(
            "benchmark.bbr.422",
            "University"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-429`]: t(
            "benchmark.bbr.429",
            "Other building for education and research"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-430`]: t(
            "benchmark.bbr.430",
            "(DEPRECATED) Hospital, nursing home, maternity clinic etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-431`]: t(
            "benchmark.bbr.431",
            "Hospital"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-432`]: t(
            "benchmark.bbr.432",
            "Hospice, treatment home"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-433`]: t(
            "benchmark.bbr.433",
            "Health, medical center, and maternity clinic"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-439`]: t(
            "benchmark.bbr.439",
            "Other health-related building"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-440`]: t(
            "benchmark.bbr.440",
            "(DEPRECATED) Daycare center"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-441`]: t(
            "benchmark.bbr.441",
            "Daycare center"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-442`]: t(
            "benchmark.bbr.442",
            "Service function for residential care institutions"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-443`]: t(
            "benchmark.bbr.443",
            "Barracks"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-444`]: t(
            "benchmark.bbr.444",
            "Prison, jail etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-449`]: t(
            "benchmark.bbr.449",
            "Other building for institutional purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-490`]: t(
            "benchmark.bbr.490",
            "(DEPRECATED) Building for other institution, barracks, prison etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-510`]: t(
            "benchmark.bbr.510",
            "Cottage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-520`]: t(
            "benchmark.bbr.520",
            "(DEPRECATED) Summer camp, hostel and other vacation home except cottage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-521`]: t(
            "benchmark.bbr.521",
            "Summer camp, camping center etc."
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-522`]: t(
            "benchmark.bbr.522",
            "Commercial rental vacation home"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-523`]: t(
            "benchmark.bbr.523",
            "Vacation home"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-529`]: t(
            "benchmark.bbr.529",
            "Other building for vacation purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-530`]: t(
            "benchmark.bbr.530",
            "(DEPRECATED) Sports facility"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-531`]: t(
            "benchmark.bbr.531",
            "Clubhouse related to leisure and sport"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-532`]: t(
            "benchmark.bbr.532",
            "Swimming pool"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-533`]: t(
            "benchmark.bbr.533",
            "Sports hall"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-534`]: t(
            "benchmark.bbr.534",
            "Stadium tribune"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-535`]: t(
            "benchmark.bbr.535",
            "Stables"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-539`]: t(
            "benchmark.bbr.539",
            "Other building for sports purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-540`]: t(
            "benchmark.bbr.540",
            "Allotment house"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-585`]: t(
            "benchmark.bbr.585",
            "Annex for cottage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-590`]: t(
            "benchmark.bbr.590",
            "Other building for leisure purposes"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-910`]: t(
            "benchmark.bbr.910",
            "Garage"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-920`]: t(
            "benchmark.bbr.920",
            "Carport"
        ),
        [`${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-930`]: t(
            "benchmark.bbr.930",
            "Outhouse"
        ),
    };

    return {
        translations,
    };
};

import { gql } from "@apollo/client";

export const AUTOMATIC_SOURCES = gql`
    fragment AutomaticSource on LocationIntegration {
        id
        from
        to
        isActive
        endedAt
        type
        address
        originId
        dataQuality
        consumptionTypes
        allocationNote
    }
`;

export const ASSET_AUTOMATIC_SOURCES = gql`
    query GetAssetAutomaticSources(
        $locationId: ID!
        $useAssetIntegration: Boolean!
    ) {
        location(id: $locationId) {
            id
            displayName
            longAddress
            integrations @include(if: $useAssetIntegration) {
                ...AutomaticSource
            }
            automaticDataSources @skip(if: $useAssetIntegration) {
                ...AssetAutomaticDataSourceItem
            }
        }
    }
    ${AUTOMATIC_SOURCES}
    fragment AssetAutomaticDataSourceItem on AssetAutomaticDataSource {
        id
        from
        to
        type
        consumptionType
        identifier
        dataQuality
        address
        nationalPropertyId
        vertical
        status
        source
        allocationNote
        integrationId
        allocationObjectType
        propertyIds
    }
`;

export const PROPERTY_INFO = gql`
    fragment PropertyInfoFragment on AssetPropertyTree {
        id
        name
        objectType
    }
`;

export const GET_PROPERTY_TREE_FOR_ALLOCATION = gql`
    query GetPropertyTreeForAllocation($assetId: ID!) {
        getAssetPropertyTree(assetId: $assetId) {
            ...PropertyInfoFragment
            children {
                ...PropertyInfoFragment
                children {
                    ...PropertyInfoFragment
                    children {
                        ...PropertyInfoFragment
                        children {
                            ...PropertyInfoFragment
                            children {
                                ...PropertyInfoFragment
                                children {
                                    ...PropertyInfoFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${PROPERTY_INFO}
`;

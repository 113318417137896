import { gql } from "@apollo/client";

export const ASSET_DETAILS_QUERY = gql`
    query GetAllAssetDetails(
        $locationId: ID!
        $eventType: ChangeLogEventType = ASSET_UPDATED
    ) {
        changeLogsFieldsCount(recordId: $locationId, eventType: $eventType)

        location(id: $locationId) {
            id
            externalId
            constructionDate
            displayName
            propertyCount
            buildings {
                nationalIdentifier
                shortAddress
                longAddress
                buildingArea
                constructionDate
                buildingCategory {
                    label
                    useCode
                }
            }
            ownership
            purchaseDate
            saleDate
            management
            legalOwner
            buildingArea
            propertyType
            shortAddress
            longAddress
            ownedArea
            integrations {
                id
                type
                originId
                data {
                    ... on IntegrationEpcLocationIntegrationData {
                        municipality
                        property
                        building
                    }
                }
            }
        }
    }
`;

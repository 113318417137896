import { useParams } from "react-router-dom";

import { S } from "./dataSourceDrawer.styles";
import { PropertyAllocationField } from "./DrawerFields";
import { AutomaticSourceRowData } from "../automaticSourcesTable.types";

type Props = {
    data: AutomaticSourceRowData;
};

export const AllocationDetailsPanel = (props: Props) => {
    const { data } = props;

    const { locationId } = useParams();

    return (
        <S.Panel>
            <PropertyAllocationField
                id={data.integrationId}
                assetName={data.assetName}
                assetId={locationId ?? ""}
            />
        </S.Panel>
    );
};

import { TextFieldProps } from "@mui/material";

import { S } from "./numberInput.style";

type RequiredTextFieldProps = Pick<
    TextFieldProps,
    "value" | "onChange" | "disabled" | "size" | "variant"
>;

type Props = RequiredTextFieldProps & {
    inputProps?: { min?: number; max?: number, step?: number };
    endAdornment?: string;
};

export const NumberInput = (props: Props) => {
    const { endAdornment, ...rest } = props;

    return (
        <S.NumberInput {...rest} type="number" InputProps={{ endAdornment }} />
    );
};

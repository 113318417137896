import { Box, styled } from "@mui/material";

export const S = {
    List: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        border: `1px solid ${theme.palette.grey[300]}`,
    })),

    Item: styled(Box)(({ theme }) => ({
        display: "flex",
        backgroundColor: "white",
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        justifyContent: "space-between",
        alignItems: "center",

        "&:last-child": {
            borderBottom: "none",
        },
    })),

    Body: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "top",
        gap: theme.spacing(2),
    })),

    Panel: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(8),
        alignItems: "center",
    })),

    CTA: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
        alignItems: "center",
    })),

    Content: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
    })),

    Title: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(1),
        alignItems: "center",
    })),
};

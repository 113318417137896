import { Clear } from "@mui/icons-material";
import { Box, Button, Modal, Typography, styled } from "@mui/material";

export const S = {
    Modal: styled(Modal)(() => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    })),

    ModalBody: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        position: "relative",
        justifyContent: "space-between",
        width: theme.spacing(185),
        paddingBottom: theme.spacing(6),
        backgroundColor: theme.palette.background.paper,
    })),

    Header: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        borderBottom: `${theme.spacing(0.25)} solid ${theme.palette.grey[300]}`,
        height: theme.spacing(18),
        width: "100%",
        paddingLeft: theme.spacing(8),
    })),

    HeaderTitle: styled(Typography)(({ theme }) => ({
        fontSize: theme.spacing(4.5),
        fontWeight: "bold",
        textTransform: "capitalize",
    })),

    Title: styled(Typography)(({ theme }) => ({
        fontSize: theme.spacing(4),
        fontWeight: "bold",
        textTransform: "capitalize",
        marginBottom: theme.spacing(4),
    })),

    ClosedIconBox: styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: theme.spacing(12.5),
        width: theme.spacing(12.5),
        marginLeft: "auto",
        marginRight: theme.spacing(4),
    })),

    ClosedIcon: styled(Clear)(({ theme }) => ({
        transition: "ease-out 0.1s all",
        display: "block",
        "&:hover": {
            cursor: "pointer",
            fontSize: theme.spacing(8),
        },
    })),

    Body: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        padding: theme.spacing(6),
        marginBottom: theme.spacing(6),
    })),

    Row: styled(Box)(({ theme }) => ({
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        padding: theme.spacing(2),
    })),

    Footer: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: theme.spacing(2),
    })),

    Button: styled(Button)(() => ({
        textTransform: "capitalize",
    })),
};

import { useQuery } from "@apollo/client";
import { Delete, Add } from "@mui/icons-material";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { VerticalCoverage } from "glue/VerticalCoverage";
import {
    AssessmentVertical,
    AssetPropertyTree,
    ConsumptionType,
    GetAssetPropertyTreeQuery,
    GetAssetPropertyTreeQueryVariables,
} from "graphql-types/graphql";
import {
    getPropertyObjectTypeTranslation,
    getPropertyOwnershipTranslation,
} from "utils/translations";

import { flatPropertyTree } from "./asset-property-list.helper";
import { S } from "./asset-property-list.styles";
import { PropertyFlatTree } from "./asset-property-list.types";
import { AssetSourceLabel } from "./AssetSourceLabel";
import { PropertySourcesCount } from "./PropertySourcesCount";
import { RemovePropertyDialog } from "../../dialogs";
import { AddPropertyModal } from "../../modals";
import { GET_ASSET_PROPERTY_TREE } from "../../queries";
import { ModalProperty } from "../../types";

type Props = {
    assetId: string;
    onItemDelete: (itemId: string) => void;
    onItemAdd: (item: ModalProperty, parentId: string) => void;
};

export const AssetPropertyList = (props: Props) => {
    const { assetId, onItemDelete, onItemAdd } = props;

    const { t } = useTranslation();
    const { useConsumptionAllocation } = useFlags();

    const [removePropertyItem, setRemovePropertyItem] =
        useState<AssetPropertyTree | null>(null);

    const [addPropertyItem, setAddPropertyItem] =
        useState<PropertyFlatTree | null>(null);

    const [openRemovePropertyModal, setRemovePropertyModal] = useState(false);
    const [toggleAddPropertyModal, setToggleAddPropertyModal] = useState(false);

    const { data, loading } = useQuery<
        GetAssetPropertyTreeQuery,
        GetAssetPropertyTreeQueryVariables
    >(GET_ASSET_PROPERTY_TREE, {
        skip: !useConsumptionAllocation,
        variables: {
            assetId,
        },
    });

    function onRemoveProperty(item: AssetPropertyTree) {
        setRemovePropertyItem(item);
        setRemovePropertyModal(true);
    }

    function onAddProperty(item: PropertyFlatTree) {
        setAddPropertyItem(item);
        setToggleAddPropertyModal(true);
    }

    function onCloseRemovePropertyModal() {
        setRemovePropertyModal(false);
        setRemovePropertyItem(null);
    }

    function onConfirmRemoveProperty(item: AssetPropertyTree | null) {
        if (item) {
            onItemDelete(item.id);
        }

        setRemovePropertyItem(null);
        setRemovePropertyModal(false);
    }

    function onConfirmAddProperty(
        property: ModalProperty,
        item: AssetPropertyTree | null | undefined
    ) {
        if (item) {
            onItemAdd(property, item.id);
        }

        setAddPropertyItem(null);
        setToggleAddPropertyModal(false);
    }

    if (loading) {
        return null;
    }

    const items = data?.getAssetPropertyTree || [];

    if (!items) {
        return null;
    }

    const flatPropertyList = flatPropertyTree(items as AssetPropertyTree[]);

    const list = flatPropertyList.map((item) => {
        const {
            id,
            depth,
            name,
            address,
            objectType,
            ownershipType,
            allocationsCount,
            qualityCount,
        } = item;

        const objectTypeLabel = getPropertyObjectTypeTranslation(objectType);

        const icon =
            depth > 0 ? <SubdirectoryArrowRightIcon color="info" /> : null;

        const paddingLeftValue = depth <= 1 ? 0 : `${depth * 20}px`;

        const { zip, city, street } = address || {};

        const hasAddress = zip || city || street;

        const addTooltipTitle =
            depth >= 5
                ? t(
                      "assetDetailsComponent.addPropertyTooltip",
                      "The property cannot be segmented further"
                  )
                : null;

        return (
            <S.Item key={id}>
                <S.Body sx={{ paddingLeft: paddingLeftValue }}>
                    {icon}
                    <S.Content>
                        <S.Title>
                            <Typography variant="h4">{`${objectTypeLabel} ${name} `}</Typography>
                            <Typography variant="body1">{`( ${capitalize(
                                getPropertyOwnershipTranslation(ownershipType)
                            )} )`}</Typography>
                        </S.Title>

                        {hasAddress && (
                            <Typography variant="body1">{`${zip} ${street}, ${city}`}</Typography>
                        )}
                    </S.Content>
                </S.Body>
                <S.Panel>
                    <PropertySourcesCount qualityCount={qualityCount} />
                    <VerticalCoverage>
                        <VerticalCoverage.Vertical
                            active={
                                allocationsCount[
                                    ConsumptionType.ENERGY_ELECTRICITY
                                ] > 0
                            }
                            type={AssessmentVertical.ELECTRICITY}
                        >
                            <VerticalCoverage.Popover>
                                <AssetSourceLabel
                                    count={
                                        allocationsCount[
                                            ConsumptionType.ENERGY_ELECTRICITY
                                        ]
                                    }
                                />
                            </VerticalCoverage.Popover>
                        </VerticalCoverage.Vertical>
                        <VerticalCoverage.Vertical
                            active={
                                allocationsCount[
                                    ConsumptionType.ENERGY_HEATING
                                ] > 0
                            }
                            type={AssessmentVertical.HEATING}
                        >
                            <VerticalCoverage.Popover>
                                <AssetSourceLabel
                                    count={
                                        allocationsCount[
                                            ConsumptionType.ENERGY_HEATING
                                        ]
                                    }
                                />
                            </VerticalCoverage.Popover>
                        </VerticalCoverage.Vertical>
                        <VerticalCoverage.Vertical
                            active={allocationsCount[ConsumptionType.WATER] > 0}
                            type={AssessmentVertical.WATER}
                        >
                            <VerticalCoverage.Popover>
                                <AssetSourceLabel
                                    count={
                                        allocationsCount[ConsumptionType.WATER]
                                    }
                                />
                            </VerticalCoverage.Popover>
                        </VerticalCoverage.Vertical>
                    </VerticalCoverage>
                    <S.CTA>
                        <Tooltip title={addTooltipTitle}>
                            <Box>
                                <IconButton
                                    disabled={depth >= 5}
                                    size="small"
                                    color="primary"
                                    onClick={() => onAddProperty(item)}
                                >
                                    <Add />
                                </IconButton>
                            </Box>
                        </Tooltip>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => onRemoveProperty(item)}
                        >
                            <Delete />
                        </IconButton>
                    </S.CTA>
                </S.Panel>
            </S.Item>
        );
    });

    return (
        <>
            <S.List>{list}</S.List>
            <RemovePropertyDialog
                assetProperty={removePropertyItem}
                isOpen={openRemovePropertyModal}
                onClose={onCloseRemovePropertyModal}
                onConfirm={onConfirmRemoveProperty}
            />
            <AddPropertyModal
                assetProperty={addPropertyItem}
                isOpen={toggleAddPropertyModal}
                onConfirm={onConfirmAddProperty}
                onClose={() => setToggleAddPropertyModal(false)}
            />
        </>
    );
};

import { useMutation } from "@apollo/client";

import {
    GetAssetAutomaticSourcesQuery,
    UpdateAssetIntegrationMutation,
    UpdateAssetIntegrationMutationVariables,
} from "graphql-types/graphql";

import {
    UPDATE_ASSET_INTEGRATION,
    UPDATE_NOTE_FRAGMENT,
} from "./drawerFields.query";
import { ASSET_AUTOMATIC_SOURCES } from "../../automaticSourcesTable.query";

export enum UpdateType {
    Ownership = "Ownership",
    Note = "Note",
}

export const useUpdateAssetIntegration = (
    integrationId: string,
    updateType: UpdateType
) => {
    const fragmentId = `AssetAutomaticDataSource:${integrationId}`;
    const fragment = UPDATE_NOTE_FRAGMENT;

    const cacheFragment = {
        id: fragmentId,
        fragment,
    };

    const [updateAssetIntegration] = useMutation<
        UpdateAssetIntegrationMutation,
        UpdateAssetIntegrationMutationVariables
    >(UPDATE_ASSET_INTEGRATION, {
        update: (cache, { data }) => {
            const updated = data?.updateOneLocationIntegration || null;
            if (!updated) return;
            const { __typename, id, type, assetId, ...rest } = updated;

            if (type !== "EPC") {
                cache.writeFragment({
                    ...cacheFragment,
                    data: rest,
                });
                return;
            }

            const query = cache.readQuery<GetAssetAutomaticSourcesQuery>({
                query: ASSET_AUTOMATIC_SOURCES,
                variables: { locationId: assetId },
            });
            const sources = query?.location?.automaticDataSources;
            if (!sources) return;

            const updatedSources = sources.map((source) =>
                source.integrationId === id ? { ...source, ...rest } : source
            );

            cache.writeQuery({
                query: ASSET_AUTOMATIC_SOURCES,
                variables: { locationId: assetId },
                data: {
                    location: {
                        ...query?.location,
                        automaticDataSources: updatedSources,
                    },
                },
            });
        },
    });

    return { updateAssetIntegration };
};

import { gql } from "@apollo/client";

export const GET_PROPERTY_ALLOCATIONS_FRAGMENT = gql`
    fragment PropertyAllocationFragment on AssetPropertyTree {
        allocations {
            id
            assetIntegration {
                id
                dataQuality
                consumptionTypes
            }
        }
    }
`;

export const GET_ASSET_PROPERTY_TREE_FRAGMENT = gql`
    fragment AssetPropertyTreeFragment on AssetPropertyTree {
        id
        name
        address
        objectType
        ownershipType
        ...PropertyAllocationFragment
    }
    ${GET_PROPERTY_ALLOCATIONS_FRAGMENT}
`;

export const GET_ASSET_PROPERTY_TREE = gql`
    query GetAssetPropertyTree($assetId: ID!) {
        getAssetPropertyTree(assetId: $assetId) {
            ...AssetPropertyTreeFragment
            children {
                ...AssetPropertyTreeFragment
                children {
                    ...AssetPropertyTreeFragment
                    children {
                        ...AssetPropertyTreeFragment
                        children {
                            ...AssetPropertyTreeFragment
                            children {
                                ...AssetPropertyTreeFragment
                                children {
                                    ...AssetPropertyTreeFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${GET_ASSET_PROPERTY_TREE_FRAGMENT}
`;

export const ADD_ASSET_PROPERTY = gql`
    mutation AddAssetProperty($input: AddAssetPropertyInput!) {
        addAssetProperty(input: $input) {
            id
            name
        }
    }
`;

export const DELETE_ASSET_PROPERTY = gql`
    mutation DeleteAssetProperty($id: ID!) {
        deleteOneProperty(input: { id: $id }) {
            id
        }
    }
`;

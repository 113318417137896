import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useActiveAssetGroup } from "components/AssetsGroup/hooks/useActiveAssetGroup";
import Loading from "components/Loading/Loading";
import { BenchmarkMarkers } from "components/types";
import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";
import {
    GetPortfolioOverviewAssessmentSummariesQuery,
    GetPortfolioOverviewAssessmentSummariesQueryVariables,
} from "graphql-types/graphql";
import { useBenchmarkMarker, useBenchmarkMarkersStorage } from "hooks";
import { BenchmarksContext } from "utils/benchmarksContext";

import Card from "../../Cards/Card";
import ChartContainer from "../Charts/ChartContainer";
import { MuiDataQualityChart } from "../Charts/MuiDataQualityChart";
import { PortfolioOverviewChartHeader } from "../Charts/PortfolioOverviewChartHeader";
import { YearlyBuildingAverageChart } from "../Charts/YearlyBuildingAverageChart";
import { YearlyIntensityChart } from "../Charts/YearlyIntensityChart";
import { YearlyTotalEmissionsChart } from "../Charts/YearlyTotalEmissionsChart";
import { DownloadProvider } from "../helpers/useDownloadContext";
import { getSummaryYearOverviewOnType } from "../helpers/utilities";
import { PORTFOLIO_OVERVIEW_QUERY } from "../portfolioQueries";
import { ChartType } from "../types";

const Overview = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [activeGroup] = useActiveAssetGroup();

    const { findStorageBenchmarkMarker } = useBenchmarkMarkersStorage();

    const { assessmentType } = useAssessmentDataTypeContext();
    const { convertToBenchmarkMarker } = useBenchmarkMarker();
    const { benchmarks, markers } = useContext(BenchmarksContext);

    const activeChartParams = searchParams.get("element");

    const [activeMarkers, setActiveMarkers] = useState<BenchmarkMarkers>([]);

    const [activeChart, setActiveChart] = useState(
        activeChartParams ? parseInt(activeChartParams, 10) : 0
    );

    useEffect(() => {
        if (activeGroup.id === "") {
            return;
        }

        const benchmarkMarkers = convertToBenchmarkMarker(
            assessmentType,
            benchmarks
        );

        const chartBenchmarkMarkers = [
            ...benchmarkMarkers,
            ...markers.legacyBenchmarks,
        ].map((marker) => {
            const storedBenchmark = findStorageBenchmarkMarker(
                activeGroup.id,
                marker.id
            );

            if (storedBenchmark) {
                return {
                    ...marker,
                    isVisible: storedBenchmark.isVisible,
                };
            }

            return marker;
        });

        setActiveMarkers(chartBenchmarkMarkers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [benchmarks, markers, assessmentType, activeGroup]);

    const { data, loading } = useQuery<
        GetPortfolioOverviewAssessmentSummariesQuery,
        GetPortfolioOverviewAssessmentSummariesQueryVariables
    >(PORTFOLIO_OVERVIEW_QUERY, {
        variables: { from: `${DateTime.local().year}` },
    });

    const assessmentSummaries = useMemo(() => {
        const locations = data?.me.organization?.getLocations;

        if (!locations) return [];

        return getSummaryYearOverviewOnType([...locations], assessmentType);
    }, [data, assessmentType]);

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <DownloadProvider>
            <Card>
                <PortfolioOverviewChartHeader
                    markers={activeMarkers}
                    activeChart={activeChart}
                    setMarkers={setActiveMarkers}
                    setActiveChart={setActiveChart}
                />

                <ChartContainer>
                    {activeChart === ChartType.PORTFOLIO_INTENSITY && (
                        <YearlyIntensityChart
                            activeTab={assessmentType}
                            assessmentSummaries={assessmentSummaries}
                            benchmarkMarkers={activeMarkers}
                        />
                    )}
                    {activeChart === ChartType.TOTAL_EMISSION && (
                        <YearlyTotalEmissionsChart
                            assessmentSummaries={assessmentSummaries}
                        />
                    )}
                    {activeChart === ChartType.WEIGHTED_AVERAGE && (
                        <YearlyBuildingAverageChart
                            activeTab={assessmentType}
                            assessmentSummaries={assessmentSummaries}
                        />
                    )}
                    {activeChart === ChartType.DATA_QUALITY && (
                        <MuiDataQualityChart activeTab={assessmentType} />
                    )}
                </ChartContainer>
            </Card>
        </DownloadProvider>
    );
};

export default Overview;

import { t } from "i18next";
import { capitalize, startCase, toLower, orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { useFilterTranslations } from "components/AssetsGroup";
import {
    getLegacyBenchmarkId,
    getLegacyBenchmarkMarkerColor,
} from "components/PortfolioDashboard/Charts/utils";
import { LegacyBenchmarkMarker } from "components/types";
import { LegacyBenchmark, LegacyBenchmarkType } from "graphql-types/graphql";
import { useBenchmarkTranslations, useRegionTranslation } from "hooks";

function formatSegment(input: string): string {
    return input ? startCase(toLower(input.replace(/_/g, " "))) : "";
}

function formatSegmentLabel(
    benchmarkType: LegacyBenchmarkType,
    segmentLabel: string
): string {
    return match(benchmarkType)
        .with(LegacyBenchmarkType.BBR_PROPERTY_TYPE, () => {
            return segmentLabel;
        })
        .with(LegacyBenchmarkType.CRREM_PROPERTY_TYPE, () => "")
        .with(LegacyBenchmarkType.BUILDING_AGE, () => `(${segmentLabel})`)
        .with(
            LegacyBenchmarkType.BUILDING_SIZE,
            () => `(${segmentLabel} ${t("filter.m2", "m²")})`
        )
        .with(LegacyBenchmarkType.CONSUMPTION_TYPE, () => "")
        .with(LegacyBenchmarkType.LEGACY_PROPERTY_TYPE, () => "")
        .with(LegacyBenchmarkType.NATIONAL, () => "")
        .with(LegacyBenchmarkType.REGION, () => "")
        .with(LegacyBenchmarkType.VERTICAL, () => "")
        .exhaustive();
}

export function useLegacyBenchmarkMarker() {
    const { t } = useTranslation();
    const { getRegionName } = useRegionTranslation();
    const { translations } = useFilterTranslations();
    const { translations: benchmarkTranslations } = useBenchmarkTranslations();

    const convertToLegacyBenchmarkMarker = (
        legacyBenchmarks: LegacyBenchmark[]
    ): LegacyBenchmarkMarker[] => {
        let benchmarks: LegacyBenchmarkMarker[] = [];

        legacyBenchmarks.forEach((legacyBenchmark) => {
            const {
                benchmarkType,
                countryCode,
                segment,
                segmentLabel,
                values,
                numberOfBuildings,
            } = legacyBenchmark;

            const id = getLegacyBenchmarkId(legacyBenchmark);
            const currentBenchmark = benchmarks.find(
                (benchmark) => benchmark?.id === id
            );

            if (!currentBenchmark) {
                const color = getLegacyBenchmarkMarkerColor(benchmarkType);
                const title = `${translations[benchmarkType]} ${capitalize(
                    t("benchmark.singular", "benchmark")
                )}`;

                const benchmarkSegmentLabel =
                    benchmarkType === LegacyBenchmarkType.BBR_PROPERTY_TYPE
                        ? benchmarkTranslations[
                              `${LegacyBenchmarkType.BBR_PROPERTY_TYPE}-${segment}`
                          ]
                        : segmentLabel;

                const subTitle = `${getRegionName(
                    countryCode ?? ""
                )}, ${formatSegment(segment)}`;

                const description = `${t(
                    "benchmark.legacyBenchmarkDescription",
                    "Legacy’s Benchmarks are metrics designed to help property owners assess building emissions and energy performance using specific thresholds"
                )}`;

                benchmarks.push({
                    ...legacyBenchmark,
                    id,
                    color,
                    title,
                    isVisible: false,
                    subTitle,
                    description,
                    values,
                    numberOfBuildings,
                    segmentLabel: formatSegmentLabel(
                        benchmarkType,
                        benchmarkSegmentLabel
                    ),
                });
                return;
            }

            benchmarks = benchmarks.map((benchmark) => {
                if (benchmark.id !== currentBenchmark.id) {
                    return benchmark;
                }

                return {
                    ...benchmark,
                    values: [...benchmark.values, ...values],
                    numberOfBuildings:
                        benchmark.numberOfBuildings + numberOfBuildings,
                };
            });

            benchmarks = orderBy(benchmarks, ["benchmarkType"], ["desc"]);
        });

        return benchmarks;
    };

    return {
        convertToLegacyBenchmarkMarker,
    };
}
